import { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Modal, ModalBody, ModalHeader, ModalFooter, Input, Label } from 'reactstrap';
import Button from '@components/ButtonLoader';
import { showError, showSuccess } from '@components/Notifications';
import { useAddReportMutation } from './campaignsApi';
import T from '@json/en.json';
import SummaryAndFindings from '@components/SummaryAndFindings';

const pictureInitialValues = { preview: null, file: null };

const getInitialState = campaign => {
  const { keyword_rankings_modal_comments, link_building_modal_comments, page_authority_modal_comments, share_of_voice_modal_comments } = campaign.config.report || {};

  const initState = {
    apply_client_url: true,
    apply_control_url: true,
    client_logo: '',
    client_name: '',
    description: '',
    general: '',
    keyword_ranking: keyword_rankings_modal_comments || '',
    link_building: link_building_modal_comments || '',
    page_authority: page_authority_modal_comments || '',
    share_of_voice: share_of_voice_modal_comments || '',
    title: '',
    include_client_name: false,
  };

  if (campaign.config && campaign.config.report) {
    initState.client_name = campaign.config.report.client_name;
    initState.general = campaign.config.report.general;
  }

  return initState;
};

const requiredFields = ['title', 'client_name'];

const reader = new FileReader();

const CreateReport = ({ setModalIsOpen, modalIsOpen, campaign, onSuccess }) => {
  const [formData, setFormData] = useState(getInitialState(campaign));
  const [formDataError, setFormDataError] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditingSummary, setIsEditingSummary] = useState(false);
  const [picture, setPicture] = useState(pictureInitialValues);
  const [addReportMutation, { isLoading }] = useAddReportMutation();

  const listener = useCallback(() => {
    if (!reader.result) {
      setPicture(pictureInitialValues);
    }
    setFormData(i => ({ ...i, client_logo: reader.result }));
  }, []);

  const imageConvert = useCallback(
    file => {
      reader.addEventListener('load', listener);
      reader.readAsDataURL(file);

      setFormData(i => ({ ...i, client_logo: reader.result }));
    },
    [listener]
  );

  useEffect(() => {
    if (!modalIsOpen || !campaign.config?.report?.client_logo_url) {
      return false;
    }

    const logoUrl = campaign.config.report.client_logo_url;

    fetch(logoUrl)
      .then(res => res.blob())
      .then(logoFile => {
        setPicture({ preview: logoUrl, file: logoFile });
        imageConvert(logoFile);
      });

    return () => reader.removeEventListener('load', listener);
  }, [modalIsOpen, campaign.config, imageConvert, listener]);

  const handleFormChange = ({ target }) => {
    setErrorMessage('');
    setFormData(i => ({ ...i, [target.name]: target.value }));
  };

  const toggleCheckbox = name => {
    setFormData(i => ({ ...i, [name]: !i[name] }));
  };

  const uploadPicture = e => {
    const file = e.target.files[0];

    setPicture({ preview: URL.createObjectURL(file), file: file });

    imageConvert(file);
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    setFormDataError({});
    setErrorMessage('');

    for (const field of requiredFields) {
      if (!formData[field]) {
        setFormDataError(i => ({ ...i, [field]: true }));
      }
    }

    if (!requiredFields.every(i => formData[i])) {
      return false;
    }

    const { error, data } = await addReportMutation({ campaign, data: formData });

    if (error) {
      const message = "Can't create Report!";
      showError(message);

      if (error.status === 400) {
        return setErrorMessage('Form data is not valid.');
      }

      return setErrorMessage(message);
    }

    showSuccess(data.message);
    onSuccess && onSuccess(data);
    setModalIsOpen(false);
  };

  const handleSummaryAndFindingsChange = (content, key) => {
    setIsEditingSummary(true);
    handleFormChange({ target: { name: key, value: content } });
  };

  return (
    <Modal size="lg" backdrop="static" scrollable isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)}>
      <ModalHeader>
        <div>
          <b>Add a Summary</b>
        </div>
      </ModalHeader>
      <form onSubmit={onFormSubmit}>
        <ModalBody className={clsx(isLoading && 'dim')}>
          <Label title="Required" className="fs mb-1">
            Title
            {!formData.title && <span className="text-danger ms-1">*</span>}
          </Label>
          <Input name="title" placeholder="" className="form-control" type="text" onChange={handleFormChange} value={formData.title} />
          <div className="form-error mb-3 mt-0">{formDataError.title ? T.ERRORS.EMPTY : ''}</div>
          <Input
            className="form-control"
            rows="2"
            name="description"
            maxLength="10000"
            placeholder="Description"
            type="textarea"
            onChange={handleFormChange}
            value={formData.description}
          />
          <h6 className="mt-3 mb-1">Report Logo</h6>
          <input name="client_logo" type="file" placeholder="Logo" className="form-control mb-3" accept="image/*" onChange={uploadPicture} />
          {picture.preview && (
            <div className="w-100 d-flex p-3 justify-content-center">
              <img src={picture.preview} className="picture-preview" />
            </div>
          )}
          <Label title="Required" className="fs mb-1">
            Client Name
            {!formData.client_name && <span className="text-danger ms-1">*</span>}
          </Label>
          <Input name="client_name" placeholder="" className="form-control" type="text" onChange={handleFormChange} value={formData.client_name} />

          <div className="mx-4 mt-2" onClick={() => toggleCheckbox('include_client_name')}>
            <Input type="checkbox" name="include_client_name" checked={formData.include_client_name} readOnly />
            <Label className="ms-2">Include in Report</Label>
          </div>

          <div className="form-error mb-3 mt-0">{formDataError.client_name ? T.ERRORS.EMPTY : ''}</div>

          <SummaryAndFindings initialContent={formData} onUpdateEditable={keys => setIsEditingSummary(keys.length > 0)} onSave={handleSummaryAndFindingsChange} />

          <div className="mx-4" onClick={() => toggleCheckbox('apply_client_url')}>
            <Input type="checkbox" name="apply_client_url" checked={formData.apply_client_url} readOnly />
            <Label className="ms-2">Campaign URL</Label>
          </div>
          <div className="mx-4" onClick={() => toggleCheckbox('apply_control_url')}>
            <Input type="checkbox" name="apply_control_url" checked={formData.apply_control_url} readOnly />
            <Label className="ms-2">Control URL</Label>
          </div>
        </ModalBody>
        <ModalFooter className="background-footer-model ">
          {errorMessage && <p className="text-danger m-2">{errorMessage}</p>}
          {isEditingSummary && <p className="text-warning m-2">Pending changes</p>}
          <Button disabled={isLoading} className="btn btn-outline-secondary m-2 cancel-bg" name="btn" onClick={() => setModalIsOpen(false)}>
            Cancel
          </Button>
          <Button disabled={isLoading || !requiredFields.every(i => formData[i]) || isEditingSummary} loading={isLoading} className="btn btn-accent m-2" name="btn" type="submit">
            Submit
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateReport;
