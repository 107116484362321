import { memo, useCallback, useState, useMemo, Fragment, useEffect } from 'react';
import { Row } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { CampaignSovTable, PageAuthTable, CampaignLinkBuildingTable, CampaignKeywordRankingsTable, KeywordDistributionTable } from '@components/campaigns/view/tables';
import { CampaignSOVChart, CampaignLinksChart, PageAuthGraph, CampaignKeywordRankingChart, KeywordDistributionGraph } from '@components/campaigns/view/graphs';
import { useGetByIdQuery, useUpdateMutation, useUpdateConfigMutation } from '@components/campaigns/campaignsApi';
import { getFiltersFor } from '@utils';
import QEditor from '@elements/QEditor/QEditor';
import { useParams } from 'react-router-dom';
import SummaryAndFindings from '@components/SummaryAndFindings';
import { showError, showSuccess } from '@components/Notifications';
import 'react-accessible-accordion/dist/fancy-example.css';
import { useDataConfig } from '@hooks/dataConfig';

const ChainChildren = ({ main, children, hidden }) => {
  const [showChildren, setShowChildren] = useState(false);

  if (hidden) {
    return <Fragment>{children}</Fragment>;
  }

  if (!showChildren) {
    return main({ showChildren: () => setShowChildren(true) });
  }

  return (
    <Fragment>
      {main({ showChildren: () => setShowChildren(true) })}
      {children}
    </Fragment>
  );
};

const NoPriorityKeywords = ({ onLoadEnd, chartWidth }) => {
  useEffect(() => {
    onLoadEnd && onLoadEnd();
  }, [onLoadEnd]);

  return (
    <div style={{ flexGrow: 1, maxWidth: chartWidth, width: chartWidth }} className="position-relative mx-auto">
      <h5 className="text-center fs-6 pt-3 pb-2">Priority Keywords</h5>
      <div className="border text-center" style={{ padding: '8rem 1rem' }}>
        No Priority Keywords selected.
      </div>
    </div>
  );
};

const AccordionComponent = ({ maxUrls }) => {
  const { id } = useParams();
  const dataConfig = useDataConfig(id);
  const [updateConfig, updateConfigStatus] = useUpdateConfigMutation();
  const [updateCampaign, updateCampaignResult] = useUpdateMutation();
  const { data } = useGetByIdQuery({ id, params: { max_urls: maxUrls } });
  const [openItems, setOpenItems] = useState([]);
  const [sovTableReady, setSovTableReady] = useState(false);
  const [keywordDistributionTableReady, setKeywordDistributionTableReady] = useState(false);
  const [keywordRankingsTableReady, setKeywordRankingsTableReady] = useState(false);

  const updateGraphKey = useCallback(
    (content, key) => {
      const updateObject = {
        id,
        graph_data: {
          [key]: {
            description: content,
          },
        },
      };
      return updateCampaign(updateObject).unwrap();
    },
    [updateCampaign, id]
  );
  const chartProps = {
    campaignId: id,
    updateContent: updateGraphKey,
    graph_data: data ? data.campaign.graph_data : {},
    isSaving: updateCampaignResult.isLoading,
  };

  const isFilterEnabled = (...filters) => {
    for (const filter of filters) {
      if (!dataConfig.filters[filter]) {
        return false;
      }
    }
    return true;
  };

  const isSegmentationVisible = (metric, segmentation) => {
    if (!dataConfig.pdf_export[metric]) {
      return false;
    }

    if (!dataConfig.hide_non_selected) {
      return true;
    }

    return dataConfig.pdf_export[metric].includes(segmentation);
  };

  const chartWidth = useMemo(() => {
    const enabledFiltersCount = Object.entries(dataConfig.filters).filter(i => i[1]).length;

    if (enabledFiltersCount < 2) {
      return 720;
    }

    return 480;
  }, [dataConfig]);

  const noFiltersEnabled = useMemo(() => {
    return Object.entries(dataConfig.filters).filter(i => i[1]).length === 0;
  }, [dataConfig]);

  const summaryAndFindingsInitialContent = useMemo(() => {
    const { keyword_rankings_modal_comments, link_building_modal_comments, page_authority_modal_comments, share_of_voice_modal_comments, general = '' } = dataConfig.report;

    return {
      general,
      keyword_ranking: keyword_rankings_modal_comments || '',
      link_building: link_building_modal_comments || '',
      page_authority: page_authority_modal_comments || '',
      share_of_voice: share_of_voice_modal_comments || '',
    };
  }, [dataConfig.report]);

  const handleSummaryAndFindingsSave = async (content, key) => {
    const formatKeyName = key => {
      if (key === 'general') {
        return key;
      }
      if (key === 'keyword_ranking') {
        return `${key}s_modal_comments`;
      }
      return `${key}_modal_comments`;
    };

    const { error } = await updateConfig({
      campaign: { id },
      config: {
        report: {
          ...dataConfig.report,
          [formatKeyName(key)]: content,
        },
      },
    });

    if (error) {
      return showError('Error saving!');
    }

    showSuccess('Saved');
  };

  return (
    <Accordion allowMultipleExpanded allowZeroExpanded onChange={setOpenItems}>
      <AccordionItem uuid="6">
        <AccordionItemHeading>
          <AccordionItemButton>Summary & Findings</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {openItems.includes('6') && (
            <Row>
              <SummaryAndFindings initialContent={summaryAndFindingsInitialContent} onSave={handleSummaryAndFindingsSave} isSaving={updateConfigStatus.isLoading} />
            </Row>
          )}
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem uuid="1">
        <AccordionItemHeading>
          <AccordionItemButton>Share of Voice</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {openItems.includes('1') && (
            <>
              {sovTableReady && (
                <Row>
                  <div className="w-100">
                    <div className="d-flex justify-content-center">
                      <div className="w-100 mw-100">
                        <QEditor
                          isSaving={updateCampaignResult.isLoading}
                          initialContent={chartProps.graph_data?.sov_graph?.description || ''}
                          updateContent={content => updateGraphKey(content, 'sov_graph')}
                          className="mb-0"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-wrap w-100">
                      <ChainChildren
                        hidden={!((isFilterEnabled('all_tracked') || noFiltersEnabled) && isSegmentationVisible('share_of_voice', 'all_tracked'))}
                        main={({ showChildren }) => (
                          <CampaignSOVChart
                            {...chartProps}
                            onLoadEnd={showChildren}
                            width={chartWidth}
                            showTable={!noFiltersEnabled}
                            segmentKey="all_tracked"
                            title="All Tracked Keywords"
                          />
                        )}
                      >
                        <ChainChildren
                          hidden={!(isFilterEnabled('top_10') && isSegmentationVisible('share_of_voice', 'top_10'))}
                          main={({ showChildren }) => <CampaignSOVChart {...chartProps} onLoadEnd={showChildren} width={chartWidth} segmentKey="top_10" title="Top 10 KWs/Page" />}
                        >
                          <ChainChildren
                            hidden={!(isFilterEnabled('top_25') && isSegmentationVisible('share_of_voice', 'top_25'))}
                            main={({ showChildren }) => (
                              <CampaignSOVChart {...chartProps} onLoadEnd={showChildren} width={chartWidth} segmentKey="top_25" title="Top 25 KWs/Page" />
                            )}
                          >
                            <ChainChildren
                              hidden={!(isFilterEnabled('top_50') && isSegmentationVisible('share_of_voice', 'top_50'))}
                              main={({ showChildren }) => (
                                <CampaignSOVChart {...chartProps} onLoadEnd={showChildren} width={chartWidth} segmentKey="top_50" title="Top 50 KWs/Page" />
                              )}
                            >
                              <ChainChildren
                                hidden={!(isFilterEnabled('position_lte_10') && isSegmentationVisible('share_of_voice', 'position_lte_10'))}
                                main={({ showChildren }) => (
                                  <CampaignSOVChart {...chartProps} onLoadEnd={showChildren} width={chartWidth} segmentKey="position_lte_10" title="KWs Rankings: 10 or Better" />
                                )}
                              >
                                <ChainChildren
                                  hidden={!(isFilterEnabled('position_lte_20') && isSegmentationVisible('share_of_voice', 'position_lte_20'))}
                                  main={({ showChildren }) => (
                                    <CampaignSOVChart {...chartProps} onLoadEnd={showChildren} width={chartWidth} segmentKey="position_lte_20" title="KWs Rankings: 20 or Better" />
                                  )}
                                >
                                  <ChainChildren
                                    hidden={!(isFilterEnabled('position_1to3') && isSegmentationVisible('share_of_voice', 'position_1to3'))}
                                    main={({ showChildren }) => (
                                      <CampaignSOVChart {...chartProps} onLoadEnd={showChildren} width={chartWidth} segmentKey="position_1to3" title="All KWs Ranking: 1-3" />
                                    )}
                                  >
                                    <ChainChildren
                                      hidden={!(isFilterEnabled('priority') && isSegmentationVisible('share_of_voice', 'priority'))}
                                      main={({ showChildren }) =>
                                        getFiltersFor('priority', dataConfig).priority.length === 0 ? (
                                          <NoPriorityKeywords onLoadEnd={showChildren} chartWidth={chartWidth} />
                                        ) : (
                                          <CampaignSOVChart {...chartProps} width={chartWidth} segmentKey="priority" title="Priority Keywords" onLoadEnd={showChildren} />
                                        )
                                      }
                                    ></ChainChildren>
                                  </ChainChildren>
                                </ChainChildren>
                              </ChainChildren>
                            </ChainChildren>
                          </ChainChildren>
                        </ChainChildren>
                      </ChainChildren>
                    </div>
                  </div>
                </Row>
              )}
              <Row>
                <CampaignSovTable onLoadEnd={() => setSovTableReady(true)} campaignId={id} />
              </Row>
            </>
          )}
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem uuid="2">
        <AccordionItemHeading>
          <AccordionItemButton>Page Authority</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {openItems.includes('2') && (
            <>
              <Row>
                <PageAuthGraph {...chartProps} />
              </Row>
              <Row>
                <PageAuthTable campaignId={id} />
              </Row>
            </>
          )}
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem uuid="3">
        <AccordionItemHeading>
          <AccordionItemButton>Link Building</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {openItems.includes('3') && (
            <>
              <Row>
                <CampaignLinksChart {...chartProps} />
              </Row>
              <Row>
                <CampaignLinkBuildingTable campaignId={id} />
              </Row>
            </>
          )}
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem uuid="4">
        <AccordionItemHeading>
          <AccordionItemButton>Keyword Rankings</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {openItems.includes('4') && (
            <>
              {keywordRankingsTableReady && (
                <Row>
                  <div className="w-100">
                    <div className="d-flex justify-content-center">
                      <div className="w-100 mw-100">
                        <QEditor
                          isSaving={updateCampaignResult.isLoading}
                          initialContent={chartProps.graph_data?.keyword_rankings?.description || ''}
                          updateContent={content => updateGraphKey(content, 'keyword_rankings')}
                          className="mb-0"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-wrap w-100">
                      <ChainChildren
                        hidden={!((isFilterEnabled('all_tracked') || noFiltersEnabled) && isSegmentationVisible('keyword_rankings', 'all_tracked'))}
                        main={({ showChildren }) => (
                          <CampaignKeywordRankingChart
                            onLoadEnd={showChildren}
                            {...chartProps}
                            width={chartWidth}
                            showTable={!noFiltersEnabled}
                            segmentKey="all_tracked"
                            title="All Tracked Keywords"
                          />
                        )}
                      >
                        <ChainChildren
                          hidden={!(isFilterEnabled('top_10') && isSegmentationVisible('keyword_rankings', 'top_10'))}
                          main={({ showChildren }) => (
                            <CampaignKeywordRankingChart onLoadEnd={showChildren} {...chartProps} width={chartWidth} segmentKey="top_10" title="Top 10 KWs/Page" />
                          )}
                        >
                          <ChainChildren
                            hidden={!(isFilterEnabled('top_25') && isSegmentationVisible('keyword_rankings', 'top_25'))}
                            main={({ showChildren }) => (
                              <CampaignKeywordRankingChart onLoadEnd={showChildren} {...chartProps} width={chartWidth} segmentKey="top_25" title="Top 25 KWs/Page" />
                            )}
                          >
                            <ChainChildren
                              hidden={!(isFilterEnabled('top_50') && isSegmentationVisible('keyword_rankings', 'top_50'))}
                              main={({ showChildren }) => (
                                <CampaignKeywordRankingChart onLoadEnd={showChildren} {...chartProps} width={chartWidth} segmentKey="top_50" title="Top 50 KWs/Page" />
                              )}
                            >
                              <ChainChildren
                                hidden={!(isFilterEnabled('position_lte_10') && isSegmentationVisible('keyword_rankings', 'position_lte_10'))}
                                main={({ showChildren }) => (
                                  <CampaignKeywordRankingChart
                                    onLoadEnd={showChildren}
                                    {...chartProps}
                                    width={chartWidth}
                                    segmentKey="position_lte_10"
                                    title="KWs Rankings: 10 or Better"
                                  />
                                )}
                              >
                                <ChainChildren
                                  hidden={!(isFilterEnabled('position_lte_20') && isSegmentationVisible('keyword_rankings', 'position_lte_20'))}
                                  main={({ showChildren }) => (
                                    <CampaignKeywordRankingChart
                                      onLoadEnd={showChildren}
                                      {...chartProps}
                                      width={chartWidth}
                                      segmentKey="position_lte_20"
                                      title="KWs Rankings: 20 or Better"
                                    />
                                  )}
                                >
                                  <ChainChildren
                                    hidden={!(isFilterEnabled('position_1to3') && isSegmentationVisible('keyword_rankings', 'position_1to3'))}
                                    main={({ showChildren }) => (
                                      <CampaignKeywordRankingChart
                                        onLoadEnd={showChildren}
                                        {...chartProps}
                                        width={chartWidth}
                                        segmentKey="position_1to3"
                                        title="All KWs Ranking: 1-3"
                                      />
                                    )}
                                  >
                                    <ChainChildren
                                      hidden={!(isFilterEnabled('priority') && isSegmentationVisible('keyword_rankings', 'priority'))}
                                      main={({ showChildren }) =>
                                        getFiltersFor('priority', dataConfig).priority.length === 0 ? (
                                          <NoPriorityKeywords onLoadEnd={showChildren} chartWidth={chartWidth} />
                                        ) : (
                                          <CampaignKeywordRankingChart
                                            {...chartProps}
                                            width={chartWidth}
                                            segmentKey="priority"
                                            title="Priority Keywords"
                                            onLoadEnd={showChildren}
                                          />
                                        )
                                      }
                                    ></ChainChildren>
                                  </ChainChildren>
                                </ChainChildren>
                              </ChainChildren>
                            </ChainChildren>
                          </ChainChildren>
                        </ChainChildren>
                      </ChainChildren>
                    </div>
                  </div>
                </Row>
              )}
              <Row>
                <CampaignKeywordRankingsTable onLoadEnd={() => setKeywordRankingsTableReady(true)} campaignId={id} />
              </Row>
            </>
          )}
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem uuid="5">
        <AccordionItemHeading>
          <AccordionItemButton>Keyword Distribution</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {openItems.includes('5') && (
            <>
              {keywordDistributionTableReady && (
                <Row>
                  <div className="w-100">
                    <div className="d-flex justify-content-center">
                      <div className="w-100 mw-100">
                        <QEditor
                          isSaving={updateCampaignResult.isLoading}
                          initialContent={chartProps.graph_data?.keyword_distribution?.description || ''}
                          updateContent={content => updateGraphKey(content, 'keyword_distribution')}
                          className="mb-0"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-wrap w-100">
                      <ChainChildren
                        hidden={!((isFilterEnabled('all_tracked') || noFiltersEnabled) && isSegmentationVisible('keyword_distribution', 'all_tracked'))}
                        main={({ showChildren }) => (
                          <KeywordDistributionGraph
                            {...chartProps}
                            onLoadEnd={showChildren}
                            width={chartWidth}
                            showTable={!noFiltersEnabled}
                            segmentKey="all_tracked"
                            title="All Tracked Keywords"
                          />
                        )}
                      >
                        <ChainChildren
                          hidden={!(isFilterEnabled('top_10') && isSegmentationVisible('keyword_distribution', 'top_10'))}
                          main={({ showChildren }) => (
                            <KeywordDistributionGraph {...chartProps} width={chartWidth} segmentKey="top_10" title="Top 10 KWs/Page" onLoadEnd={showChildren} />
                          )}
                        >
                          <ChainChildren
                            hidden={!(isFilterEnabled('top_25') && isSegmentationVisible('keyword_distribution', 'top_25'))}
                            main={({ showChildren }) => (
                              <KeywordDistributionGraph {...chartProps} width={chartWidth} segmentKey="top_25" title="Top 25 KWs/Page" onLoadEnd={showChildren} />
                            )}
                          >
                            <ChainChildren
                              hidden={!(isFilterEnabled('top_50') && isSegmentationVisible('keyword_distribution', 'top_50'))}
                              main={({ showChildren }) => (
                                <KeywordDistributionGraph {...chartProps} width={chartWidth} segmentKey="top_50" title="Top 50 KWs/Page" onLoadEnd={showChildren} />
                              )}
                            >
                              <ChainChildren
                                hidden={!(isFilterEnabled('position_lte_10') && isSegmentationVisible('keyword_distribution', 'position_lte_10'))}
                                main={({ showChildren }) => (
                                  <KeywordDistributionGraph
                                    {...chartProps}
                                    width={chartWidth}
                                    segmentKey="position_lte_10"
                                    title="KWs Rankings: 10 or Better"
                                    onLoadEnd={showChildren}
                                  />
                                )}
                              >
                                <ChainChildren
                                  hidden={!(isFilterEnabled('position_lte_20') && isSegmentationVisible('keyword_distribution', 'position_lte_20'))}
                                  main={({ showChildren }) => (
                                    <KeywordDistributionGraph
                                      {...chartProps}
                                      width={chartWidth}
                                      segmentKey="position_lte_20"
                                      title="KWs Rankings: 20 or Better"
                                      onLoadEnd={showChildren}
                                    />
                                  )}
                                >
                                  <ChainChildren
                                    hidden={!(isFilterEnabled('position_1to3') && isSegmentationVisible('keyword_distribution', 'position_1to3'))}
                                    main={({ showChildren }) => (
                                      <KeywordDistributionGraph
                                        {...chartProps}
                                        width={chartWidth}
                                        segmentKey="position_1to3"
                                        title="All KWs Ranking: 1-3"
                                        onLoadEnd={showChildren}
                                      />
                                    )}
                                  >
                                    <ChainChildren
                                      hidden={!(isFilterEnabled('priority') && isSegmentationVisible('keyword_distribution', 'priority'))}
                                      main={({ showChildren }) =>
                                        getFiltersFor('priority', dataConfig).priority.length === 0 ? (
                                          <NoPriorityKeywords onLoadEnd={showChildren} chartWidth={chartWidth} />
                                        ) : (
                                          <KeywordDistributionGraph {...chartProps} width={chartWidth} segmentKey="priority" title="Priority Keywords" onLoadEnd={showChildren} />
                                        )
                                      }
                                    ></ChainChildren>
                                  </ChainChildren>
                                </ChainChildren>
                              </ChainChildren>
                            </ChainChildren>
                          </ChainChildren>
                        </ChainChildren>
                      </ChainChildren>
                    </div>
                  </div>
                  <p className="mx-1 mt-4">
                    Reviewing ranking distribution by URL shows pages that are ranking comfortably, vs. those that are do not have high visibility and may present opportunities for
                    the campaign.
                  </p>
                </Row>
              )}
              <Row>
                <KeywordDistributionTable onLoadEnd={() => setKeywordDistributionTableReady(true)} campaignId={id} />
              </Row>
            </>
          )}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default memo(AccordionComponent);
