import { useMemo } from 'react';
import { createYAxis, colors, getClientControlYRange } from '@shared/graph';
import BasicBarChar from '@components/BasicBarChar';

const RankDistChart = ({ monthwiseDistribution, segmentKey, dataConfig, campaignId, showLeftAxis = false, showRightAxis = true }) => {
  const { xAxis, data } = useMemo(() => {
    const xAxis = Object.keys(monthwiseDistribution ?? {});
    const oneToThree = [],
      fourToTen = [],
      elevenToFifty = [],
      fiftyPlus = [],
      average = [];
    for (const val of Object.values(monthwiseDistribution ?? {})) {
      oneToThree.push(val['1to3']);
      fourToTen.push(val['4to10']);
      elevenToFifty.push(val['11to50']);
      fiftyPlus.push(val['50plus']);
      average.push(parseFloat(val['average'].toFixed(2)));
    }
    return { xAxis, data: { oneToThree, fourToTen, elevenToFifty, fiftyPlus, average } };
  }, [monthwiseDistribution]);

  const segmentZoom = useMemo(() => {
    if (!(segmentKey && dataConfig?.zoom?.keyword_distribution)) {
      return {};
    }

    return dataConfig.zoom.keyword_distribution[segmentKey] || {};
  }, [dataConfig, segmentKey]);

  const max = useMemo(() => Math.max(...Object.values(monthwiseDistribution ?? {}).map(dist => dist.count)), [monthwiseDistribution]);

  const chr = {
    options: {
      chart: {
        id: `keyword-distribution|${segmentKey || '?'}`,
        type: 'line',
        stacked: true,
      },
      xaxis: {
        categories: xAxis,
      },
      yaxis: [
        createYAxis({
          showLabel: true,
          color: colors.black,
          title: 'Count of Ranking KWs',
          reversed: true,
          min: 0,
          max,
          showIntegersOnY: true,
          seriesName: '1 to 3',
        }),
        createYAxis({
          seriesName: '1 to 3',
          reversed: true,
          min: 0,
          max,
          showIntegersOnY: true,
        }),
        createYAxis({
          reversed: true,
          seriesName: '1 to 3',
          min: 0,
          max,
          showIntegersOnY: true,
        }),
        createYAxis({
          reversed: true,
          seriesName: '1 to 3',
          min: 0,
          max,
          showIntegersOnY: true,
        }),
        createYAxis({
          seriesName: 'Average Rank',
          opposite: true,
          reversed: true,
          showLabel: true,
          color: colors.black,
          title: 'Average Rank',
          ...getClientControlYRange({ clientArr: [0, max], controlArr: data.average, zoom: segmentZoom.right }).control,
        }),
      ],
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: ['#fff'],
        width: [1, 1, 1, 1, 4],
        dashArray: 0,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        inverseOrder: false,
        followCursor: false,
        enabledOnSeries: [0, 1, 2, 3, 4],
      },
    },
    series: [
      {
        name: '1 to 3',
        type: 'column',
        data: data.oneToThree,
        color: colors.yellow,
      },
      {
        name: '4 to 10',
        type: 'column',
        data: data.fourToTen,
        color: colors.gray,
      },
      {
        name: '11 to 50',
        type: 'column',
        data: data.elevenToFifty,
        color: colors.darkOrange,
      },
      {
        name: '50+',
        type: 'column',
        data: data.fiftyPlus,
        color: colors.tealBlue,
      },
      {
        name: 'Average Rank',
        type: 'line',
        data: data.average,
        color: colors.teal,
      },
    ],
  };

  return (
    <BasicBarChar
      sectionKey="keyword_distribution"
      segmentKey={segmentKey}
      dataConfig={dataConfig}
      campaignId={campaignId}
      showLeftAxis={showLeftAxis}
      showRightAxis={showRightAxis}
      rightRange={{ min: chr.options.yaxis[4].min, max: chr.options.yaxis[4].max }}
      graphStyle={{ maxWidth: 720 }}
      chr={chr}
    />
  );
};

export default RankDistChart;
