import { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdAddCircle } from 'react-icons/md';
import { Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { formatDate } from '@utils';
import { useUpdateMutation } from '@components/urls/urlsApi';
import { MdSwapVert } from 'react-icons/md';
import clsx from 'clsx';

const UrlTypeSelector = ({ urlId, refetch, name }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [updateMutation] = useUpdateMutation();

  const handleDropdownClick = async status => {
    const { error } = await updateMutation({ id: urlId, status });
    if (!error && refetch) {
      refetch();
    }
  };

  return (
    <Dropdown className="h-100" isOpen={dropdownOpen} toggle={() => setDropdownOpen(i => !i)} direction="right" size="xs">
      <DropdownToggle className="nav-link cursor-pointer p-0" tag="a">
        <p className="my-0 mx-1">
          <MdSwapVert className="fetch-text-size" />
        </p>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => handleDropdownClick(Number(name === 'Client'))} className="">
          <p className={clsx('mb-0')} style={{ fontSize: '13px', color: '#000' }}>
            Convert to {name === 'Control' ? 'Campaign' : 'Control'} URL
          </p>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const Row = ({
  index,
  name,
  start_date,
  end_date,
  url_id,
  short_url,
  links_built,
  links_built_mm,
  sov,
  sov_mm,
  page_authority,
  page_authority_mm,
  keyword_rank,
  keyword_rank_mm,
  keywords_count,
  keywords_count_mm,
  refetch,
  urlTypeSwitcher,
}) => {
  return (
    <div className="url-table-row">
      <div className="text-center">{index + 1}.</div>
      <div className="d-flex">
        <p className="my-1">{name.replace('Client', 'Campaign')}</p>
        {urlTypeSwitcher && <UrlTypeSelector refetch={refetch} urlId={url_id} name={name} />}
      </div>
      <div className="text-center">
        {formatDate(start_date, 'M/YY')} - {formatDate(end_date, 'M/YY')}
      </div>
      <div className="text-left">
        <Link className="link" to={`/urls/${url_id}`}>
          {short_url}
        </Link>
      </div>
      <div>{links_built}</div>
      <div>{links_built_mm}</div>
      <div>{sov} %</div>
      <div>{sov_mm} %</div>
      <div>{page_authority}</div>
      <div>{page_authority_mm}</div>
      <div>{keyword_rank}</div>
      <div>{keyword_rank_mm}</div>
      <div>{keywords_count}</div>
      <div>{keywords_count_mm}</div>
    </div>
  );
};

const CampaignURLReportTableBody = ({ campaignData, onLoadMoreClick, isLoading, refetch, urlTypeSwitcher }) => {
  if (!campaignData) {
    return null;
  }

  const showClientLoadMore = campaignData.client_url_count > campaignData.client_url.length;
  const showControlLoadMore = campaignData.control_url_count > campaignData.control_url.length;

  const campaignUrls = campaignData.client_url.map((i, index) => <Row refetch={refetch} urlTypeSwitcher={urlTypeSwitcher} index={index} key={i.url_id} {...i} />);
  const controlUrls = campaignData.control_url.map((i, index) => <Row refetch={refetch} urlTypeSwitcher={urlTypeSwitcher} index={index} key={i.url_id} {...i} />);

  return (
    <div>
      {campaignUrls}
      {showClientLoadMore && (
        <div className="w-6 d-flex justify-content-center border-top border-bottom">
          <div
            className={clsx('d-flex align-items-center justify-content-center', !isLoading && 'cursor-pointer')}
            onClick={() => !isLoading && onLoadMoreClick && onLoadMoreClick()}
            style={{}}
          >
            {!isLoading && <MdAddCircle style={{ fontSize: '20px', color: '#017bfe' }} />}
            {isLoading && <Spinner size="sm" color="dark" />}
            <p className="mx-2 my-1">{isLoading ? 'Loading' : 'Load more'}...</p>
          </div>
        </div>
      )}
      {controlUrls}
      {showControlLoadMore && (
        <div className="w-6 d-flex justify-content-center border-top border-bottom">
          <div
            className={clsx('d-flex align-items-center justify-content-center', !isLoading && 'cursor-pointer')}
            onClick={() => !isLoading && onLoadMoreClick && onLoadMoreClick()}
            style={{}}
          >
            {!isLoading && <MdAddCircle style={{ fontSize: '20px', color: '#017bfe' }} />}
            {isLoading && <Spinner size="sm" color="dark" />}
            <p className="mx-2 my-1">{isLoading ? 'Loading' : 'Load more'}...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignURLReportTableBody;
