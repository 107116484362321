import AxisButton from './AxisButton';
import Chart from 'react-apexcharts';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useUpdateConfigMutation } from './campaigns/campaignsApi';
import { MdDownload } from 'react-icons/md';

const DEFAULT_RANGE = { min: 0.1, max: 0.1 };

const BasicBarChar = ({
  isLoading = false,
  chr,
  sectionKey,
  segmentKey,
  campaignId,
  dataConfig,
  showLeftAxis = false,
  showRightAxis = false,
  rightRange = DEFAULT_RANGE,
  leftRange = DEFAULT_RANGE,
  graphStyle = '',
}) => {
  const [updateConfig, updateConfigStatus] = useUpdateConfigMutation();
  const leftIncrement = useMemo(() => (leftRange.max - leftRange.min) / 5, [leftRange]);
  const rightIncrement = useMemo(() => (rightRange.max - rightRange.min) / 5, [rightRange]);

  const handleArrowClick = async (side, direction) => {
    if (updateConfigStatus.isLoading) {
      return false;
    }

    const baseValues = { left: 1, right: 1 };
    const zoom = dataConfig.zoom || {};
    const values = zoom[sectionKey] && zoom[sectionKey][segmentKey] ? { ...baseValues, ...zoom[sectionKey][segmentKey] } : baseValues;

    if (side === 'left') {
      if (direction == 'up') {
        values.left = values.left + leftIncrement;
      }

      if (direction == 'down') {
        values.left = values.left - leftIncrement;
      }

      if (direction == 'reset') {
        values.left = 1;
      }

      values.left = Math.round(values.left * 1000) / 1000;
    }

    if (side === 'right') {
      if (direction == 'up') {
        values.right = values.right + rightIncrement;
      }

      if (direction == 'down') {
        values.right = values.right - rightIncrement;
      }

      if (direction == 'reset') {
        values.right = 1;
      }

      values.right = Math.round(values.right * 1000) / 1000;
    }

    await updateConfig({
      campaign: { id: campaignId },
      config: {
        zoom: { ...zoom, [sectionKey]: { ...zoom[sectionKey], [segmentKey]: values } },
      },
    });
  };

  const handleDownload = async () => {
    const graphId = chr.options.chart?.id || 'chart';
    const { imgURI } = await window.ApexCharts.exec(graphId, 'dataURI');
    const element = document.createElement('a');
    element.href = imgURI;
    element.download = `${campaignId}_${graphId.replace('|?', '').replace('|', '_')}.png`;
    element.click();
  };

  return (
    <div className="d-flex align-items-center w-full">
      {showLeftAxis && <AxisButton side="left" showArrow buttonStyle={{ margin: '0.75rem 0' }} handleArrowClick={handleArrowClick} />}
      <div className={clsx('w-100 mx-auto position-relative', isLoading && 'dim')} style={{ ...graphStyle }}>
        <Chart options={chr.options} series={chr.series} />
        <div className="cursor-pointer absolute mb-4 my-2 opacity-80" style={{ right: 0, bottom: 0, position: 'absolute' }} onClick={handleDownload}>
          <MdDownload className="fetch-text-size" />
        </div>
      </div>
      {showRightAxis && <AxisButton side="right" showArrow buttonStyle={{ margin: '0.75rem 0' }} handleArrowClick={handleArrowClick} />}
    </div>
  );
};

export default BasicBarChar;
