import { useEffect } from 'react';
import { useGetKeywordsDistributionQuery } from '@components/campaigns/campaignsApi';
import RankDistChart from '@components/urls/graphs/RankDistChart';
import clsx from 'clsx';
import { getFiltersFor } from '@utils';
import GraphButtons from '@components/GraphButtons';
import { useDataConfig } from '@hooks/dataConfig';
import { Spinner } from 'reactstrap';

const KeywordDistributionGraph = ({ campaignId, segmentKey, title = '', width, onLoadEnd }) => {
  const dataConfig = useDataConfig(campaignId);
  const { data: { monthwise_distribution } = {}, isFetching, status } = useGetKeywordsDistributionQuery({ id: campaignId, filters: getFiltersFor(segmentKey, dataConfig) });

  useEffect(() => {
    if (status === 'fulfilled') {
      onLoadEnd && onLoadEnd();
    }
  }, [onLoadEnd, status]);

  return (
    <div style={{ flexGrow: 1, maxWidth: width, width }} className="position-relative mx-auto">
      {!isFetching && <GraphButtons sectionKey="keyword_distribution" segmentKey={segmentKey} dataConfig={dataConfig} campaignId={campaignId} />}
      <div className="d-flex align-items-center justify-content-center">
        <h5 className="text-center fs-6 my-2">{title || 'Rank Distribution'}</h5>
        {isFetching && <Spinner size="sm" className="mx-2 my-4" />}
      </div>
      <RankDistChart
        monthwiseDistribution={monthwise_distribution}
        width={width}
        className={clsx('mx-auto', isFetching && 'dim')}
        segmentKey={segmentKey}
        dataConfig={dataConfig}
        campaignId={campaignId}
      />
    </div>
  );
};

export default KeywordDistributionGraph;
