import { memo, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Table } from 'reactstrap';
import { useGetKeywordsDistributionQuery } from '@components/campaigns/campaignsApi';
import { Spinner } from 'reactstrap';
const Header = () => {
  return (
    <thead className="bg-light">
      <tr>
        <th scope="col">URL</th>
        <th scope="col">
          Rank: <br /> 1 to 3
        </th>
        <th scope="col">
          Rank: <br />4 to 10
        </th>
        <th scope="col">
          Rank: <br />
          11 to 50
        </th>
        <th scope="col">
          Rank: <br />
          50 plus
        </th>
      </tr>
    </thead>
  );
};
const THead = memo(Header);
const renderRow = (row = {}) => {
  return (
    <tr key={row.url?.id}>
      <td>{row.url?.url ?? ''}</td>
      <td>{row['1to3']}</td>
      <td>{row['4to10']}</td>
      <td>{row['11to50']}</td>
      <td>{row['50plus']}</td>
    </tr>
  );
};
const TableSection = ({ dataArray, label }) => {
  return (
    <Row>
      <Col>
        <Card className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">{label}</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <Table className="table mb-0">
              <THead />
              <tbody>{dataArray.map(renderRow)}</tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
const KeywordDistributionTable = ({ campaignId, onLoadEnd }) => {
  const { data, isFetching, status } = useGetKeywordsDistributionQuery({ id: campaignId });

  useEffect(() => {
    if (status === 'fulfilled') {
      onLoadEnd && onLoadEnd();
    }
  }, [onLoadEnd, status]);

  if (isFetching) {
    return (
      <div className="d-flex align-items-center my-6">
        <Spinner size="sm" className="mx-1" />
        <p className="mx-1 my-0">Loading table...</p>
      </div>
    );
  }

  if (data?.url_distribution?.length > 9) {
    return (
      <>
        <TableSection dataArray={data?.url_distribution.slice(0, 5) ?? []} label={'Top Box'} />
        <TableSection dataArray={data?.url_distribution.slice(-5) ?? []} label={'Bottom Box'} />
      </>
    );
  } else {
    return <TableSection dataArray={data?.url_distribution ?? []} label={'Distribution by Page'} />;
  }
};

export default KeywordDistributionTable;
