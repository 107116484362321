import { useEffect, useState, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import Accordion from './Accordion';
import StartEndMonthYearPicker from '@components/StartEndMonthYearPicker';
import T from '@json/en.json';
import PageHeader from './PageHeader';
import Section from './Section';
import { campaignBaseConfig } from '@utils';
import { CampaignURLReportTableHeader, CampaignURLReportTableBody } from '@components/campaigns/view/tables';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
  Label,
} from 'reactstrap';
import { formatDate, parseDate, ucFirst } from '@utils';
import { showSuccess, showError } from '@components/Notifications';
import { useParams } from 'react-router-dom';
import '../campaign.scss';
import { useFetchMutation, useUpdateMutation, useGetByIdQuery, useGetConfigQuery, useUpdateConfigMutation } from '@components/campaigns/campaignsApi';
import AddUrl from '../add/AddUrl';

const allFilters = ['top_10', 'top_25', 'top_50', 'priority', 'all_tracked', 'position_1to3', 'position_lte_10', 'position_lte_20'];

const KeywordsModal = ({ isOpen = false, toggle = () => true, title = null, keywords = [], onChange = () => true, types = [], isLoading = false }) => {
  const [newKeyword, setNewKeyword] = useState('');
  const [newType, setNewType] = useState(types.length ? types[0] : '');

  const newKeywordIsValid = useMemo(() => {
    if (!newKeyword) {
      return false;
    }

    if (keywords.filter(i => types.includes(i.type)).some(i => i.value === newKeyword)) {
      return false;
    }

    return true;
  }, [newKeyword, keywords, types]);

  const selectAllChecked = useMemo(() => {
    return keywords.filter(i => types.includes(i.type)).every(i => i.is_active);
  }, [keywords, types]);

  const handleAdd = value => {
    const newKeywords = [...keywords];

    for (const newKeyword of value
      .split(/[\t\n,]+/)
      .map(i => i.trim())
      .filter(Boolean)) {
      if (newKeywords.some(i => i.value === newKeyword)) {
        continue;
      }

      newKeywords.push({ value: newKeyword, type: newType, is_active: true });
    }

    if (newKeywords.length !== keywords.length) {
      onChange(newKeywords);
      setNewKeyword('');
    }
  };

  const handleDelete = keyword => {
    onChange(keywords.filter(i => i.value !== keyword.value || i.type !== keyword.type));
  };

  const handleSelectAllClick = () => {
    onChange(
      keywords.map(i => {
        if (!types.includes(i.type)) {
          return i;
        }
        return { ...i, is_active: !selectAllChecked };
      })
    );
  };

  const handleActiveChange = keyword => {
    onChange(
      keywords.map(i => {
        if (i.value === keyword.value && i.type === keyword.type) {
          return { ...i, is_active: !i.is_active };
        }
        return i;
      })
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>{title || 'keywords'}</ModalHeader>
      <ModalBody className="py-4">
        <Table borderless>
          <thead>
            <tr>
              <th className="text-center">
                <div className="d-flex">
                  {keywords.filter(i => types.includes(i.type)).length > 1 && (
                    <div className="mx-2">
                      <input type="checkbox" checked={selectAllChecked} readOnly onChange={handleSelectAllClick} />
                    </div>
                  )}
                  <span>Active</span>
                </div>
              </th>
              <th>Keyword</th>
              {types.length > 1 && <th>Type</th>}
              <th className="text-center">Remove</th>
            </tr>
          </thead>
          <tbody>
            {keywords
              .filter(i => types.includes(i.type))
              .map(i => {
                return (
                  <tr key={`${i.value}|${i.type}`} className="border-top border-bottom">
                    <td className="text-center py-0">
                      <input type="checkbox" checked={i.is_active} readOnly onChange={() => handleActiveChange(i)} />
                    </td>
                    <td className="py-0">{i.value}</td>
                    {types.length > 1 && <td>{ucFirst(i.type.replace('exclude_', ''))}</td>}
                    <td className="text-center py-0">
                      <i className="material-icons m-2 row-icons" onClick={() => handleDelete(i)}>
                        delete
                      </i>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <div className="form-group">
          <div className="d-flex align-items-center justify-content-around mx-auto mt-4" style={{ maxWidth: '320px' }}>
            <Input disabled={false} name="keyword" type="textarea" onChange={({ target }) => setNewKeyword(target.value)} value={newKeyword} style={{ height: '34px' }} />
            <Button disabled={!newKeywordIsValid || isLoading} className="btn btn-accent mx-2" name="btn" onClick={() => handleAdd(newKeyword)}>
              Add
            </Button>
          </div>
          {types.length > 1 && (
            <div className="d-flex justify-content-around">
              {types.map(type => {
                return (
                  <div key={type} className="inline-radio" onClick={() => setNewType(type)}>
                    <Input type="radio" checked={type === newType} readOnly />
                    <Label className="ms-2">{ucFirst(type.replace('exclude_', ''))}</Label>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        <Button disabled={false} className="btn btn-outline-secondary m-2 cancel-bg" name="btn" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const maxUrlsInitial = 15;

const ViewCampaign = () => {
  const { id } = useParams();
  const [updateCampaign, updateCampaignResult] = useUpdateMutation();
  const [fetchCampaign, fetchCampaignResult] = useFetchMutation();
  const [maxUrls, setMaxUrls] = useState(maxUrlsInitial);
  const { data, isLoading: getByIdIsLoading, isFetching: getByIdIsFetching, refetch } = useGetByIdQuery({ id, params: { max_urls: maxUrls } });
  const { data: configData } = useGetConfigQuery(id);
  const [updateConfig] = useUpdateConfigMutation();
  const [segmentViewOpen, setSegmentViewOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [urlTypeSwitcher, setUrlTypeSwitcher] = useState(false);

  const [reportDate, setReportDate] = useState({
    report_graph_start: null,
    report_graph_end: null,
    error: { start: '', end: '' },
  });

  const [fetchDate, setFetchDate] = useState({
    start_date: null,
    end_date: null,
    error: { start: '', end: '' },
  });

  const campaignData = useMemo(() => {
    if (!data) {
      return { campaign: {} };
    }

    return data;
  }, [data]);

  useEffect(() => {
    if (!campaignData.client_url) {
      return false;
    }

    const dates = campaignData.client_url.map(i => i.start_date).sort();
    setFetchDate(i => ({ ...i, start_date: parseDate(dates.shift(), 'YYYY-MM-DD') }));

    setReportDate(i => ({
      ...i,
      report_graph_start: parseDate(campaignData.campaign.report_graph_start, 'YYYY-MM-DD'),
      report_graph_end: parseDate(campaignData.campaign.report_graph_end, 'YYYY-MM-DD'),
    }));
  }, [campaignData.client_url, campaignData.campaign.report_graph_start, campaignData.campaign.report_graph_end]);

  const toggleIgnoreExclusionList = async () => {
    await updateConfig({ campaign: campaignData.campaign, config: { ignore_exclusion_list: !configData.ignore_exclusion_list } });
  };

  const onKeywordsChange = async keywords => {
    await updateConfig({
      campaign: campaignData.campaign,
      config: { keywords },
    });
  };

  const startDateHandler = useCallback(value => {
    setReportDate(state => ({ ...state, report_graph_start: value, error: { ...state.error, start: !value } }));
  }, []);

  const endDateHandler = useCallback(value => {
    setReportDate(state => ({ ...state, report_graph_end: value, error: { ...state.error, end: !value } }));
  }, []);

  const fetchStartDateHandler = useCallback(value => {
    setFetchDate(state => ({ ...state, start_date: value }));
  }, []);

  const fetchEndDateHandler = useCallback(value => {
    if (!value) {
      return null;
    }

    setFetchDate(state => ({ ...state, end_date: value, error: { ...state.error, end: false } }));
  }, []);

  const updateReportRange = useCallback(async () => {
    setReportDate(state => ({ ...state, error: { start: !reportDate.report_graph_start, end: !reportDate.report_graph_end } }));

    if (!reportDate.report_graph_start || !reportDate.report_graph_end || reportDate.report_graph_start > reportDate.report_graph_end) {
      return false;
    }

    const response = await updateCampaign({
      id,
      name: campaignData.campaign.name,
      report_graph_start: `${formatDate(reportDate.report_graph_start, 'YYYY-MM-15')}`,
      report_graph_end: `${formatDate(reportDate.report_graph_end, 'YYYY-MM-15')}`,
    });

    if (response.error) {
      return showError('Error updating Campaign!');
    }

    refetch();
    showSuccess(T.REPORT.UPDATE);
  }, [id, campaignData.campaign.name, reportDate.report_graph_end, reportDate.report_graph_start, updateCampaign, refetch]);

  const updateFetchRange = useCallback(async () => {
    setFetchDate(state => ({ ...state, error: { end: !fetchDate.end_date } }));

    if (!fetchDate.end_date || fetchDate.start_date > fetchDate.end_date) {
      return false;
    }

    const { data, error } = await fetchCampaign({
      campaign: campaignData.campaign,
      data: { start_date: formatDate(fetchDate.start_date, 'YYYY-MM-15'), end_date: formatDate(fetchDate.end_date, 'YYYY-MM-15') },
    });

    if (error) {
      return;
    }

    showSuccess(data.message);
  }, [fetchDate, fetchCampaign, campaignData.campaign]);

  const campaignConfig = useMemo(() => {
    return { ...campaignBaseConfig, ...configData };
  }, [configData]);

  const isFilterEnabled = useCallback(
    filter => {
      return (configData && configData.filters && configData.filters[filter]) || false;
    },
    [configData]
  );

  const toggleFilter = useCallback(
    async filter => {
      if (!campaignData.campaign || !campaignData.campaign.id) {
        return false;
      }

      const filters = { ...configData.filters };

      if (filter === 'all') {
        const value = !allFilters.every(i => configData.filters[i]);
        allFilters.forEach(key => (filters[key] = value));
      } else {
        filters[filter] = !isFilterEnabled(filter);
      }

      await updateConfig({
        campaign: campaignData.campaign,
        config: { filters },
      });
    },
    [configData, campaignData, isFilterEnabled, updateConfig]
  );

  const hideNonSelected = async () => {
    if (!campaignData.campaign || !campaignData.campaign.id) {
      return false;
    }

    const updateConfigParams = {
      campaign: campaignData.campaign,
      config: {
        hide_non_selected: !configData.hide_non_selected,
        filters: { ...configData.filters },
      },
    };

    if (updateConfigParams.config.hide_non_selected) {
      Object.keys(updateConfigParams.config.filters).forEach(key => (updateConfigParams.config.filters[key] = true));
    }

    await updateConfig(updateConfigParams);
  };

  if (getByIdIsLoading || !configData) {
    return (
      <Container fluid className="main-content-container d-flex align-items-center justify-content-center">
        <Spinner className="m-4" />
      </Container>
    );
  }

  return (
    <Container fluid className="main-content-container container-fluid px-4">
      {campaignData.campaign.id && <PageHeader maxUrls={maxUrls} campaignData={campaignData} />}

      <Card className="mb-4">
        <Row className="py-2 px-3">
          <Col sm="12" md="6">
            <p className="m-0 fs-6">{campaignData.campaign.name}</p>
          </Col>
          <Col sm="12" md="6" className="d-flex align-items-center justify-content-md-end">
            <div className="me-2 fw-bold text-primary">Report Date Range:</div>
            <div className="text-dark">
              {`${formatDate(campaignData.campaign.report_graph_start, 'MM/DD/YYYY')} - ${formatDate(campaignData.campaign.report_graph_end, 'MM/DD/YYYY')}`}
            </div>
          </Col>
        </Row>
      </Card>

      <Row>
        <Section title="Campaign URLS" count={campaignData.client_url_count} />
        <Section title="Control URLS" count={campaignData.control_url_count} />
        <Section title="Links Built" count={campaignData.links_built} />
        <Section title="Avg PA of Linkers" count={campaignData.avg_pa_linkers} />
      </Row>

      <Row>
        <Col>
          <Card className="mb-4">
            <div className="d-flex justify-content-between align-items-end mt-3">
              <StartEndMonthYearPicker
                startDate={reportDate?.report_graph_start}
                endDate={reportDate?.report_graph_end}
                startDateHandler={startDateHandler}
                endDateHandler={endDateHandler}
                onButtonClick={updateReportRange}
                formDataError={reportDate.error}
                loading={updateCampaignResult.isLoading}
                submitText={T.GENRIC.SUBMIT}
                labelStartText={`${T.REPORT.REPORT} ${T.CAMPAIGN.FIELD_NAMES.START_DATE}`}
                labelEndText={`${T.REPORT.REPORT} ${T.CAMPAIGN.FIELD_NAMES.END_DATE}`}
                className="max-w-xl mx-4"
              />
              <div className="mx-4 d-flex align-items-end">
                <Dropdown isOpen={segmentViewOpen} toggle={() => setSegmentViewOpen(i => !i)} direction="down">
                  <DropdownToggle data-toggle="dropdown" tag="span">
                    <div className="d-flex align-items-center bg-gray px-2 rounded cursor-pointer">
                      <i className="material-icons fs-3" style={{ padding: '3px 0 6px 0' }}>
                        analytics
                      </i>
                      <p className="mx-2 mb-0 mt-0 fs-8 fw-bold py-2 px-4 text-center">Segment View</p>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className="text-nowrap">
                    <div className={clsx('px-3 pt-2 pb-1 d-flex mx-2 dropdown-item-filter', configData.hide_non_selected && 'dim')} onClick={() => toggleFilter('all')}>
                      <input type="checkbox" readOnly checked={allFilters.every(i => configData.filters[i]) && !configData.hide_non_selected} />
                      <p className="ml-2 my-0">View All</p>
                    </div>
                    <div className="px-3 pt-2 pb-1 d-flex mx-2 dropdown-item-filter" onClick={hideNonSelected}>
                      <input type="checkbox" readOnly checked={configData.hide_non_selected} />
                      <p className="ml-2 my-0">View only included in PDF</p>
                    </div>
                    <DropdownItem divider />
                    <div className={clsx('px-3 py-2 d-flex bg-gray mx-2 dropdown-item-filter', configData.hide_non_selected && 'dim')} onClick={() => toggleFilter('all_tracked')}>
                      <input type="checkbox" readOnly checked={isFilterEnabled('all_tracked')} />
                      <p className="ml-2 my-0">All Tracked KWs</p>
                    </div>
                    <div className={clsx('px-3 py-2 d-flex bg-gray mx-2 dropdown-item-filter', configData.hide_non_selected && 'dim')} onClick={() => toggleFilter('top_50')}>
                      <input type="checkbox" readOnly checked={isFilterEnabled('top_50')} />
                      <p className="ml-2 my-0">Top 50 KWs/Page</p>
                    </div>
                    <div className={clsx('px-3 py-2 d-flex bg-gray mx-2 dropdown-item-filter', configData.hide_non_selected && 'dim')} onClick={() => toggleFilter('top_25')}>
                      <input type="checkbox" readOnly checked={isFilterEnabled('top_25')} />
                      <p className="ml-2 my-0">Top 25 KWs/Page</p>
                    </div>
                    <div className={clsx('px-3 py-2 d-flex bg-gray mx-2 dropdown-item-filter', configData.hide_non_selected && 'dim')} onClick={() => toggleFilter('top_10')}>
                      <input type="checkbox" readOnly checked={isFilterEnabled('top_10')} />
                      <p className="ml-2 my-0">Top 10 KWs/Page</p>
                    </div>
                    <DropdownItem divider />
                    <div
                      className={clsx('px-3 py-2 d-flex bg-gray mx-2 dropdown-item-filter', configData.hide_non_selected && 'dim')}
                      onClick={() => toggleFilter('position_lte_20')}
                    >
                      <input type="checkbox" readOnly checked={isFilterEnabled('position_lte_20')} />
                      <p className="ml-2 my-0">KWs Rankings: 20 or Better</p>
                    </div>
                    <div
                      className={clsx('px-3 py-2 d-flex bg-gray mx-2 dropdown-item-filter', configData.hide_non_selected && 'dim')}
                      onClick={() => toggleFilter('position_lte_10')}
                    >
                      <input type="checkbox" readOnly checked={isFilterEnabled('position_lte_10')} />
                      <p className="ml-2 my-0">KWs Rankings: 10 or Better</p>
                    </div>
                    <div
                      className={clsx('px-3 py-2 d-flex bg-gray mx-2 dropdown-item-filter', configData.hide_non_selected && 'dim')}
                      onClick={() => toggleFilter('position_1to3')}
                    >
                      <input type="checkbox" readOnly checked={isFilterEnabled('position_1to3')} />
                      <p className="ml-2 my-0">All KWs Ranking: 1-3</p>
                    </div>
                    <DropdownItem divider />
                    <div className={clsx('px-3 py-2 d-flex bg-gray mx-2 dropdown-item-filter', configData.hide_non_selected && 'dim')} onClick={() => toggleFilter('priority')}>
                      <input type="checkbox" readOnly checked={isFilterEnabled('priority')} />
                      <p className="ml-2 my-0">Priority KWs</p>
                    </div>
                  </DropdownMenu>
                </Dropdown>
                <div className="px-3 py-2 d-flex bg-gray rounded mx-2 cursor-pointer" onClick={toggleIgnoreExclusionList}>
                  <input type="checkbox" readOnly checked={configData.ignore_exclusion_list || false} />
                  <p className="ml-2 my-0 fw-bold fs-8 text-center">Ignore Exclusion List</p>
                </div>
                <div className="d-flex flex-wrap justify-content-end">
                  <div>
                    <Button
                      className={clsx('btn btn-accent mx-2 mt-2')}
                      disabled={modalOpen === 'priority_keywords'}
                      type="button"
                      onClick={() => setModalOpen('priority_keywords')}
                    >
                      Edit Priority KWs
                    </Button>
                  </div>
                  <div>
                    <Button
                      className={clsx('btn btn-accent mx-2 mt-2')}
                      disabled={modalOpen === 'exclusion_keywords'}
                      type="button"
                      onClick={() => setModalOpen('exclusion_keywords')}
                    >
                      Edit Exclusion KWs
                    </Button>
                  </div>
                </div>
                <KeywordsModal
                  isOpen={modalOpen === 'priority_keywords'}
                  title="Priority Keywords"
                  onChange={onKeywordsChange}
                  toggle={() => setModalOpen(false)}
                  keywords={campaignConfig.keywords}
                  types={['priority']}
                  isLoading={updateCampaignResult.isLoading}
                />
                <KeywordsModal
                  isOpen={modalOpen === 'exclusion_keywords'}
                  title="Exclusion Keywords"
                  onChange={onKeywordsChange}
                  toggle={() => setModalOpen(false)}
                  keywords={campaignConfig.keywords}
                  types={['exclude_containing', 'exclude_exact']}
                  isLoading={updateCampaignResult.isLoading}
                />
              </div>
            </div>
            <CardBody>
              <Accordion maxUrls={maxUrls} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-4">
            <CardHeader className="border-bottom">
              <h5 className="m-0">URL Report</h5>
              <div className="url-report-control">
                <div className="d-flex">
                  <AddUrl campaignId={campaignData.campaign.id} />
                  <div className="btn-container mx-2">
                    <Button color="primary" onClick={() => setUrlTypeSwitcher(i => !i)}>
                      {urlTypeSwitcher ? 'Disable URL Switch' : 'Enable URL Switch'}
                    </Button>
                  </div>
                </div>
                <StartEndMonthYearPicker
                  startDate={fetchDate.start_date}
                  endDate={fetchDate.end_date}
                  startDateHandler={fetchStartDateHandler}
                  endDateHandler={fetchEndDateHandler}
                  onButtonClick={updateFetchRange}
                  loading={fetchCampaignResult.isLoading}
                  submitText={T.CAMPAIGN.FETCH}
                  labelStartText={`${T.CAMPAIGN.FETCH} ${T.CAMPAIGN.FIELD_NAMES.START_DATE}`}
                  labelEndText={`${T.CAMPAIGN.FETCH} ${T.CAMPAIGN.FIELD_NAMES.END_DATE}`}
                  className="max-w-xl mt-4 mb-1"
                  formDataError={fetchDate.error}
                />
              </div>
            </CardHeader>
            <CardBody className="fetched-table px-3 pt-0 pb-3 ">
              <div className="table mb-0 url-table">
                <CampaignURLReportTableHeader />
                <CampaignURLReportTableBody
                  onLoadMoreClick={() => setMaxUrls(i => i + maxUrlsInitial)}
                  isLoading={getByIdIsLoading || getByIdIsFetching}
                  campaignData={campaignData}
                  refetch={refetch}
                  urlTypeSwitcher={urlTypeSwitcher}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewCampaign;
