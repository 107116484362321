import { useEffect } from 'react';
import { dispatch } from 'use-bus';
import { getToken } from '@utils';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { showSuccess } from '@components/Notifications';
import routes from './routes';
import withTracker from './withTracker';
import reconnectingWebSocket from 'reconnecting-websocket';
import { API_WS_TASKS_URL } from '@redux/apiEndPoint';

const withSession = WrappedComponent => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (getToken() || location.pathname.endsWith('/login') || location.pathname.endsWith('/logout')) {
      return null;
    }

    history.push('/login');
  }, [history, location.pathname]);

  return WrappedComponent;
};

const App = () => {
  const handleMessage = ({ data }) => {
    const message = JSON.parse(data);
    const event = { type: message.type, payload: { uuid: message.uuid } };

    console.info('Websocket Message:', message);

    if (message.type !== 'task-succeeded' || !message.result) {
      return dispatch(event);
    }

    const result = message.result.slice(1, -1);

    if (result.startsWith('fetch_campaign:')) {
      event.type = 'fetch-campaign-succeeded';
      event.payload.campaign_id = result.replace('fetch_campaign:', '');
      return dispatch(event);
    }

    if (result.startsWith('setup_campaign:')) {
      event.type = 'setup-campaign-succeeded';
      event.payload.campaign_id = result.replace('setup_campaign:', '');
      showSuccess(`Campaign ${event.payload.campaign_id} created.`);
      return dispatch(event);
    }

    if (result.startsWith('report_campaign:')) {
      event.type = 'report-campaign-succeeded';
      event.payload.campaign_id = result.replace('report_campaign:', '');
      return dispatch(event);
    }

    if (result.startsWith('fetch_linkcountdocument:')) {
      event.type = 'fetch-linkcountdocument-succeeded';
      event.payload.linkcountdocument_id = result.replace('fetch_linkcountdocument:', '');
      showSuccess(`Fetch from Google Spreadsheet finished for Document ${event.payload.linkcountdocument_id}.`);
      return dispatch(event);
    }
  };

  useEffect(() => {
    for (const i of ['custom', 'failed', 'started', 'succeeded']) {
      const webSocket = new reconnectingWebSocket(`${API_WS_TASKS_URL}task-${i}/`);
      webSocket.addEventListener('message', handleMessage);
    }
  }, []);

  return (
    <>
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={withSession(
              withTracker(props => (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              ))
            )}
          />
        ))}
      </Switch>
      <ToastContainer />
    </>
  );
};

export default App;
