import clsx from 'clsx';

const AxisButton = ({ side, showArrow = false, buttonStyle = { margin: '0.75rem 0' }, handleArrowClick }) => {
  return (
    <div className={clsx(!showArrow && 'opacity-0 dim')}>
      <p className="cursor-pointer" onClick={() => handleArrowClick(side, 'up')} style={buttonStyle}>
        <i className="material-icons fs-6">keyboard_arrow_up</i>
      </p>
      <div className="d-flex cursor-pointer" onClick={() => handleArrowClick(side, 'reset')} style={buttonStyle}>
        <i className="material-icons fs-10" style={{ margin: '0 2px 5px 2px' }}>
          center_focus_weak
        </i>
      </div>
      <p className="cursor-pointer" onClick={() => handleArrowClick(side, 'down')} style={buttonStyle}>
        <i className="material-icons fs-6">keyboard_arrow_down</i>
      </p>
    </div>
  );
};

export default AxisButton;
