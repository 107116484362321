import cookies from 'js-cookie';
import dayjs from 'dayjs';

export const constants = {
  QEDITOR_MAX_LENGTH: 300,
};

export const campaignBaseConfig = {
  keywords: [],
  filters: {},
  hide_non_selected: false,
  ignore_exclusion_list: null,
  pdf_export: {
    share_of_voice: [],
    keyword_rankings: [],
    keyword_distribution: [],
  },
  featured: {
    share_of_voice: [],
    keyword_rankings: [],
    keyword_distribution: [],
  },
  report: {},
  zoom: {
    share_of_voice: {},
    page_authority: {},
    keyword_rankings: {},
    keyword_distribution: {},
  },
};

const getKeyForSlice = sliceName => {
  const stateSuffix = 'LIRV01';

  return `${sliceName}|${stateSuffix}`;
};

export const storeStateAtLocalStorage = (sliceName, state) => {
  const savedState = getStateFromLocalStorage(sliceName);
  const key = getKeyForSlice(sliceName);

  if (!state) {
    return localStorage.setItem(key, null);
  }

  return localStorage.setItem(key, JSON.stringify({ ...savedState, ...state }));
};

export const getStateFromLocalStorage = sliceName => {
  const savedState = localStorage.getItem(getKeyForSlice(sliceName));

  if (savedState) {
    return JSON.parse(savedState);
  }

  return {};
};

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = email => {
  return emailRegex.test(String(email).trim().toLowerCase());
};

export const signIn = user => cookies.set('user', JSON.stringify(user), { expires: 15, path: '/' });

export const signOut = () => cookies.remove('user', { path: '/' });

export const getToken = () => {
  // eslint-disable-next-line no-useless-escape
  const user = JSON.parse(cookies.get('user') || `{\"auth\":{}}`);

  if (!user.auth) {
    return false;
  }

  return user.auth.token;
};

export const ucFirst = (text = '') => {
  if (!text) {
    return '';
  }
  return text[0].toUpperCase() + text.slice(1);
};

export const formatPercentChange = i => {
  if (!i) {
    return 'n/c';
  }

  return `${i.toFixed(2)}%`;
};

export const percentageInAxis = value => {
  if (!value) {
    return '0 %';
  }
  return `${Math.round(value * 100) / 100} %`;
};

export const formatDate = (date = '', format = 'MM/DD/YYYY') => {
  if (!date || !format) {
    return null;
  }
  return dayjs(date).format(format);
};

export const parseDate = (date = '', format = 'MM/DD/YYYY') => {
  if (!date || !format) {
    return null;
  }
  return dayjs(date, format).toDate();
};

export const extractTextContent = htmlString => {
  const span = document.createElement('span');
  span.innerHTML = htmlString;
  return span.textContent || span.innerText || '';
};

export const getLastMonth = (last = 1) => {
  return dayjs().subtract(last, 'months').endOf('month').toDate();
};

export const percentage = (a, b) => {
  if (!b) {
    return 0;
  }
  return (a / b).toFixed(4) * 100;
};

export const percentDiff = (a, b) => percentage(a - b, Math.abs(b));

export const getFiltersFor = (filter, dataConfig) => {
  const { ignore_exclusion_list: ignoreExclusionList, keywords = [] } = dataConfig;

  const filters = {};

  if (!ignoreExclusionList) {
    filters.exclude_exact = [...new Set(keywords.filter(i => i.type === 'exclude_exact' && i.is_active).map(i => i.value))];
    filters.exclude_containing = [...new Set(keywords.filter(i => i.type === 'exclude_containing' && i.is_active).map(i => i.value))];
  }

  if (filter === 'top_10') {
    filters.top = 10;
  }

  if (filter === 'top_25') {
    filters.top = 25;
  }

  if (filter === 'top_50') {
    filters.top = 50;
  }

  if (filter === 'top_1000') {
    filters.top = 1000;
  }

  if (filter === 'position_lte_10') {
    filters.position = 10;
  }

  if (filter === 'position_lte_20') {
    filters.position = 20;
  }

  if (filter === 'position_1to3') {
    filters.position = 3;
  }

  if (filter === 'priority') {
    filters.priority = [...new Set(keywords.filter(i => i.type === 'priority' && i.is_active).map(i => i.value))];
  }

  return filters;
};
